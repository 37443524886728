var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Tournament registration ")])])], 1)], 1), _vm.isLoading ? _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _c('b-row', {
    staticClass: "m-0"
  }, [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }