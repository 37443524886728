<template>
  <div
    style="height: inherit"
  >
    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Tournament registration
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-row class="m-0">
      <b-col
        sm="12"
        md="6"
        lg="4"
        class="mb-1"
      />

    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { showJoinTournamentCard } from '@/views/tournament/tournament-utils'

export default {
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      participants: [],
      searchQuery: '',
      tournament: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    }
  },
  computed: {
    showTeamWaitingApprovalCard() {
      return false
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
  },
  watch: {
    searchQuery(searchValue) {
      if (!searchValue) {
        this.participants = this.$store.getters['tournament/getTournament']
      }

      this.participants = this.tournament?.participants.filter(
        participant => {
          if (!participant.participant) {
            return participant.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          }
          return participant.participant.name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        },
      )
    },
  },
  async created() {
    await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    this.tournament = this.$store.getters['tournament/getTournament']

    this.participants = this.tournament?.participants || []
  },
  methods: {
    showJoinTournamentCard,
  },
}
</script>
